import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="404: Not Found" />
      <div className="container-reading page page-404">
        <h1 className="page-404__title">
          N<span className="page-404__offset-flicker">ot</span>{' '}
          <span className="page-404__tilt">F</span>ou
          <span className="page-404__offset-flicker-3">n</span>d
        </h1>
        <p className="page-404__body">
          You just hit a route that doesn’t exist
        </p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
